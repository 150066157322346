<template>
  <div class="box2" :class="'skin_theme_' + skin_theme">
    <div class="box2-header">
      <div
        @click="chooseWeb(item.id)"
        class="box2-header-nav"
        v-for="item in web_list"
        :key="item.id"
        :class="item.id == choose_web_id ? 'active' : ''"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="box2-body">
      <div class="box2-a">
        <lineBox ref="lineBox" />
      </div>
      <div class="box2-b">
        <card type="1" :num="count3" v-loading="count3Loading" />
      </div>
      <div class="box2-c">
        <card type="2" :num="count4" v-loading="count4Loading" />
      </div>
    </div>
  </div>
</template>

<script>
import card from "./c/card.vue";
import lineBox from "./c/line.vue";
import { get_my_web_list } from "@/api/web.js";
import { get_view_count } from "@/api/viewcount";

export default {
  components: {
    card,
    lineBox,
  },

  data() {
    return {
      choose_web_id: 1,
      web_list: [],

      count3: 3,
      count3Loading: false,
      count4: 4,
      count4Loading: false,
    };
  },

  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
  mounted() {
    this.getWebList();
  },
  methods: {
    getWebList() {
      let p = {
        page: 1,
        page_size: 5,
      };
      this.getWebListLoading = true;
      get_my_web_list(p)
        .then((res) => {
          if (res.code == 200) {
            this.web_list = res.data.list;
            if (this.web_list.length) {
              this.chooseWeb(this.web_list[0].id * 1);
            }
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.getWebListLoading = false;
        });
    },

    chooseWeb(web_id) {
      this.choose_web_id = web_id;

      this.get_count3(web_id);
      this.get_count4(web_id);
      this.$refs.lineBox.start(web_id, "number");
    },

    get_count3(web_id) {
      let p = {
        time_type: "today",
        web_type: web_id,
        show_type: "number",
      };
      this.count3Loading = true;

      get_view_count(p)
        .then((res) => {
          this.count3 = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.count3Loading = false;
        });
    },
    get_count4(web_id) {
      let p = {
        time_type: "all",
        web_type: web_id,
        show_type: "number",
      };
      this.count4Loading = true;

      get_view_count(p)
        .then((res) => {
          this.count4 = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.count4Loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box2 {
  height: 289px;
  margin: 0 25px;
  position: relative;
  .box2-header {
    width: 100%;
    height: 52px;
    display: flex;
    .box2-header-nav {
      cursor: pointer;
      width: 132px;
      height: 52px;
      text-align: center;
      border-radius: 12px 12px 0px 0px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 34px;
      color: #fff;
    }
  }

  .box2-body {
    width: 100%;
    height: 255px;

    position: relative;
    top: -18px;
    border-radius: 18px 18px 0 0;

    .box2-a {
      width: 761px;
      height: 255px;
      border-radius: 20px;
      padding: 1px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }

    .box2-b {
      width: 84px;
      height: 120px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .box2-c {
      width: 84px;
      height: 120px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.skin_theme_one.box2 {
  .box2-a {
    background-color: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(0, 18, 153, 0.2);
  }

  .box2-header-nav {
    background: #615bff;
    opacity: 0.3;
    &:hover {
      background: #615bff;
      opacity: 1;
    }
  }
  .box2-header-nav.active {
    opacity: 1;
  }

  .box2-a {
    background: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(0, 18, 153, 0.2);
  }
}
.skin_theme_two.box2 {
  .box2-a {
    background: linear-gradient(
      205deg,
      #fdb959 0%,
      rgba(27, 26, 31, 0.19) 32%,
      #1b1a1f 100%
    );
    box-shadow: 4px 6px 16px 0px #000000;
  }

  .box2-header-nav {
    background: #ec7a6b;
    opacity: 0.3;
    &:hover {
      background: #ec7a6b;
      opacity: 1;
    }
  }
  .box2-header-nav.active {
    opacity: 1;
  }

  .box2-a {
    background-color: #232227;
    box-shadow: 4px 6px 16px 0px #000000;
  }
}
</style>