<template>
  <myCenterLayout
    select="user"
    :pageTitle="languagePackage[language].userV2_right_right_userCenter_title"
  >
    <bodyMain></bodyMain>
  </myCenterLayout>
</template>

<script>
import myCenterLayout from "@/components/layout/myCenter/index.vue";
import bodyMain from "./c/bodyMain.vue";
import languagePackage from "@/languagePackage/index.js";
export default {
  components: {
    myCenterLayout,
    bodyMain,
  },
  data() {
    return {
      languagePackage,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
</style>