<template>
  <div class="myCenter-bodyMain">
    <box1></box1>
    <div style="height: 35px"></div>
    <box2></box2>
    <div style="height: 8px"></div>
  </div>
</template>

<script>
import box1 from "./box1.vue";
import box2 from "./box2.vue";

export default {
  components: {
    box1,
    box2,
  },
  data() {
    return {
      count3: 3,
      count3Loading: false,
      count4: 4,
      count4Loading: false,
    };
  },

  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.myCenter-bodyMain {
  height: calc(100vh - 71px - 62px);
  overflow: auto;
}
</style>