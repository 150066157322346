<template>
  <div class="box1" :class="'skin_theme_' + skin_theme">
    <div class="box1-a">
      <card type="1" :num="count1" v-loading="count1Loading" />
    </div>
    <div class="box1-b">
      <card type="2" :num="count2" v-loading="count2Loading" />
    </div>
    <div class="box1-c">
      <lineBox ref="lineBox" />
    </div>
  </div>
</template>

<script>
import card from "./c/card.vue";
import lineBox from "./c/line.vue";
import { get_view_count } from "@/api/viewcount";

export default {
  components: {
    card,
    lineBox,
  },
  data() {
    return {
      count1: 1,
      count1Loading: false,
      count2: 2,
      count2Loading: false,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },

  mounted() {
    this.get_count1();
    this.get_count2();
    this.$refs.lineBox.start("all", "number");
  },

  methods: {
    get_count1() {
      let p = {
        time_type: "today",
        web_type: "all",
        show_type: "number",
      };
      this.count1Loading = true;

      get_view_count(p)
        .then((res) => {
          this.count1 = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.count1Loading = false;
        });
    },
    get_count2() {
      let p = {
        time_type: "all",
        web_type: "all",
        show_type: "number",
      };

      this.count2Loading = true;

      get_view_count(p)
        .then((res) => {
          this.count2 = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.count2Loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box1 {
  margin: 0 25px;
  height: 255px;
  position: relative;

  .box1-a {
    width: 84px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .box1-b {
    width: 84px;
    height: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .box1-c {
    width: 761px;
    height: 255px;
    padding: 1px;
    border-radius: 20px;
    overflow: hidden;

    position: absolute;
    top: 0;
    right: 0;
  }
}

.skin_theme_one.box1 {
  .box1-c {
    background-color: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(0, 18, 153, 0.2);
  }
}
.skin_theme_two.box1 {
  .box1-c {
    background: linear-gradient(
      205deg,
      #24bca8 0%,
      rgba(27, 26, 31, 0.19) 32%,
      #1b1a1f 100%
    );
    box-shadow: 4px 6px 16px 0px #000000;
  }
}
</style>