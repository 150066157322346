<template>
  <div
    :class="'skin_theme_' + skin_theme + ' type' + type"
    class="right_right_card_box"
  >
    <div class="right_right_card">
      <img v-if="type == 1" class="icon" src="./peoples.png" />
      <img v-if="type == 2" class="icon" src="./sum_peoples.png" />
      <p class="title">
        <span v-if="type == 1">
          {{
            languagePackage[language].userV2_right_right_userCenter_cardTitle[0]
          }}
        </span>
        <span v-if="type == 2">
          {{
            languagePackage[language].userV2_right_right_userCenter_cardTitle[1]
          }}
        </span>
      </p>
      <h3 class="peoples_num">{{ num }}</h3>
    </div>
  </div>
</template>

<script>
import languagePackage from "@/languagePackage/index.js";

export default {
  props: {
    type: {
      type: Number | String,
      default: 1,
    },

    num: {
      type: Number | String,
      default: 0,
    },
  },
  data() {
    return {
      languagePackage,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.right_right_card_box {
  padding: 1px;
  border-radius: 20px;
  width: 84px;
  height: 120px;
  .right_right_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    border-radius: 20px;
    .icon {
      width: 30px;
      height: 30px;
    }

    .title {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-top: 14px;
      margin-bottom: 8px;
    }
    .peoples_num {
      font-size: 20px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
    }
  }
}

.skin_theme_one.right_right_card_box {
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(0, 18, 153, 0.2);
  .title,
  .peoples_num {
    color: #333;
  }
}
.skin_theme_two.right_right_card_box.type1 {
  background: linear-gradient(
    244deg,
    rgba(251, 186, 92, 0.85) 0%,
    rgba(27, 26, 31, 0.19) 51%,
    #1b1a1f 100%
  );
  .right_right_card {
    background: linear-gradient(
      225deg,
      rgba(251, 186, 92, 0.85) 0%,
      rgba(27, 26, 31, 0.19) 51%,
      #1b1a1f 100%
    );
  }
  box-shadow: 4px 6px 16px 0px #000000;

  .title,
  .peoples_num {
    color: #fff;
  }
}
.skin_theme_two.right_right_card_box.type2 {
  background: linear-gradient(
    244deg,
    #24bca8 0%,
    rgba(27, 26, 31, 0.19) 51%,
    #1b1a1f 100%
  );
  .right_right_card {
    background: linear-gradient(
      225deg,
      #24bca8 0%,
      rgba(27, 26, 31, 0.19) 51%,
      #1b1a1f 100%
    );
  }
  box-shadow: 4px 6px 16px 0px #000000;

  .title,
  .peoples_num {
    color: #fff;
  }
}
</style>